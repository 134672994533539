<template>
  <div>
    <router-link :to="{name: 'home'}">Home</router-link> |
    <router-link :to="{name: 'about'}">About</router-link> |
    <router-link :to="{name: 'resume'}">Resume</router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>